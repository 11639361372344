import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { IUser } from "../../../../Data/Member";
import { useContext, useState } from "react";
import Bilan from "./Bilan";
import GraphPoids from "./GraphPoids";
import { isMobile } from "react-device-detect";
import DebounceTextArea from "./DebounceTextArea";
import AxiosContext from "../../../../Data/AxiosContext";

export default function MemberSummary({ member }: { member: IUser }) {
  const { axiosHelper } = useContext(AxiosContext);
  const [isExpanded, setIsExpanded] = useState(!isMobile);

  const handleNoteChange = (text: string) => {
    const data: any = {};
    data.Item = {};
    data.Item.Notes = text;
    data.FieldUpdates = ["Notes"];
    axiosHelper
      .patch(`api/Scheduler/Member/${member.Soid}`, data)
      .then((result) => {
        console.log(text);
      });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <IconButton
        onClick={() => {
          window.location.assign("/Administration");
        }}
      >
        <ArrowBack />
      </IconButton>
      <Accordion
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        sx={{ width: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            backgroundColor:
              window.location.hostname === "localhost" ? "purple" : "#2E4DFC",
          }}
        >
          <Typography textAlign={"center"} color="white">
            {member.FirstName} {member.LastName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Paper>
              <Typography textAlign={"center"}>
                {member.FirstName} {member.LastName}
              </Typography>
            </Paper>
          </Box>
          <Grid container spacing={0} textAlign={"center"}>
            <Grid item xs={5}>
              <GraphPoids data={member.Poids} />
            </Grid>
            <Grid item xs={5}>
              <Bilan program={member.Program} />
            </Grid>
            <Grid item xs={2}>
              <DebounceTextArea
                style={{ marginTop: "10px", width:"100%" }}
                debounceTimeout={1000}
                handleDebounce={(e) => handleNoteChange(e)}
                minRows={4}
                maxRows={10}
                defaultValue={member.Notes}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
