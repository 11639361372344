import * as React from "react";
import { TextareaAutosize, TextareaAutosizeProps } from "@mui/material";

type DebounceProps = {
  handleDebounce: (value: string) => void;
  debounceTimeout: number;
};

export default function DebounceTextArea(props: TextareaAutosizeProps & DebounceProps) {
  const { handleDebounce, debounceTimeout, ...other } = props;

  const timerRef = React.useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <TextareaAutosize {...other} onChange={handleChange} />;
}
